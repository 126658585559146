import React, { useEffect, useRef, useState } from 'react'
import { Btn, Modal } from 'components'
import { CSVLink } from 'react-csv'
import { compareArrays, pluralise } from 'utils/common'
import { parseCsvfile } from 'utils/parseFiles'
import { validateDomain } from 'utils/validations'
import useSnackbar from 'services/useSnackbar'
import DomainsTable from './DomainsTable'
import FileChooser from './FileChooser'


function UploadModal(props) {
    const setMessage = useSnackbar()
    const { callbackFn, setUploadModal, uploadModal } = props
    const [ isModalOpen, setIsModalOpen ] = useState(false)
    const [ uploadedFile, setUploadedFile ] = useState(null)
    const [ domains, setDomains ] = useState([])
    const validateFile = file => file.type !== "text/csv" && {error: "Invalid file type. CSV required."}
    const fileUploadProps = { setUploadedFile, validateFile, accept: ".csv" }
    const csvRef = useRef()
 
    const closeModal = () => {
        setDomains([])
        setIsModalOpen(false)
        setUploadModal(false)
    }

    useEffect(() => {
        setIsModalOpen(uploadModal)
    }, [ uploadModal ]) 
    
    const parseCsvData = csvData => {
        if (csvData) {
            if (csvData?.length > 30) {
                return setMessage({error: "Maximum 30 domains can be added."})
            }
            
            const colArray = Object.keys(csvData[0])
            if (compareArrays(colArray.map(i => i.toLowerCase()), ["domain"])) {
                const colName = colArray[0]
                const validData = csvData.filter(i => validateDomain(i[colName]))
                const lengthDiff = csvData.length - validData.length
                const validDomains = validData.map(i => i[colName])

                lengthDiff && setMessage({
                    info: `${lengthDiff} ${pluralise("domain", lengthDiff)} rejected due to incorrect format.`
                })

                if (validDomains) {
                    setDomains(validDomains)
                }
            } else {
                setMessage({error: "Incorrect data column/s. Kindly refer to the downloaded template."})
            }
        }
    }

    useEffect(() => {
        setDomains([])
        if (uploadedFile) {
            parseCsvfile(uploadedFile, parseCsvData)
        }
    }, [ uploadedFile ])

    return (
        <>
            <Modal handleClose={ closeModal } isOpen={ isModalOpen } className={ `w-${domains?.length ? 50 : 25}` }>
                {
                    domains?.length ? (
                        <div>
                            <h5 className='text-muted font-weight-bold'>Preview</h5>
                            <DomainsTable domains={ domains } />
                            <div className='text-right mt-3'>
                                <Btn onClick={() => { closeModal(); callbackFn(domains); }}>Save</Btn>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='bg--secondary rounded'>
                                <FileChooser { ...fileUploadProps } />
                            </div>
                            <p className='text-secondary m-0 pt-2 px-2 pointer' onClick={() => csvRef.current.link.click()}>Download template</p>
                        </>
                    )
                }
            </Modal>
            <CSVLink ref={ csvRef } data={[["domain"], ["google.com"], ["www.example.com"]]} filename="Blacklisted_domains_template.csv" target="_blank" />
        </>
    )
}

export default UploadModal