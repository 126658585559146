import React from 'react'
import Request from 'services/Request'
import useSnackbar from 'services/useSnackbar'
import { Btn } from 'components'
import { pathFor } from 'constants/apiPaths'
import { handleTargetingPayload, keywords_related_features } from '../utils/PayloadHandlers'
import BidstreamWrapper, { fetchBidstreamData } from '../utils/BidstreamWrapper'


function SuggestURLS(props) {
    const setMessage = useSnackbar()
    const { features, misc, primaryQuery, setTableBody, errorCallbackFns, setNinaLoader, btnClassName, setPayload, setGeos } = props
    const currentOrg = sessionStorage.getItem("currentOrg")
    const organisation_id = currentOrg && JSON.parse(currentOrg)["organisation_id"]
    
    const allFeatures = { ...features, ...(misc || {}) }
    let primary_key;
    if (primaryQuery) primary_key = Object.keys(primaryQuery)[0]

    const handleSubmit = async (event) => {
        event.preventDefault()
        
        setTableBody([])
        
        let extras = { organisation_id }
        if (primary_key) extras["primary_query"] = primary_key

        const [ payload, geoLocations ] = handleTargetingPayload(allFeatures, extras, errorCallbackFns)
        if ("error" in payload) return
        
        setNinaLoader("Analyzing")

        const bidstreamData = await fetchBidstreamData(geoLocations, 28)
        if ("error" in bidstreamData) return setMessage(bidstreamData)
                        
        const { bidstream_records, bidstream_domains } = bidstreamData
        payload["domains"] = bidstream_domains

        if(!(bidstream_records?.length && bidstream_domains?.length)) {
            setTimeout(() => setNinaLoader(false), 300)
            return setMessage({'info': "No data found."})
        }
        
        let results;
        try {
            const { statusCode, jsonResponse } = await Request(pathFor.RecommendationEngine, payload)
            
            if (statusCode === 200) {
                results = jsonResponse.data
            } else if (statusCode === 400) {
                setMessage({error: "Failed to suggest. Please try again."})
            } else {
                setMessage(jsonResponse)
            }
        } catch (error) {
            setMessage({error: "Server error. Try again after sometime."})
        }
        
        if (results) BidstreamWrapper(bidstream_records, bidstream_domains, geoLocations, results, setTableBody, () => {}, setMessage)
        
        setTimeout(() => setNinaLoader(false), 300)

        setPayload(payload)
        setGeos(geoLocations)
    }


    return (
        <Btn type="submit" 
            className={ btnClassName || "cw-2" }
            disabled={ ![ 
                "geoLocations", "intents", "emotions", 
                (primary_key === "keywords" ? "keywords" : keywords_related_features.slice(2).filter(i => allFeatures[i]?.length)?.[0] || "taxonomies") 
            ].every(i => allFeatures[i]?.length) }
            onClick={ handleSubmit }
        >Suggest URLs</Btn>
    )
}

export default SuggestURLS