import React, { useEffect, useState } from 'react'
import Sidebar from 'components/Sidebar'
import Routes from './Routes'
import dashboardPages from 'constants/dashboardPages'
import Request, { checkTpMember,checkInternalMember } from 'services/Request'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlash, faPlug } from '@fortawesome/free-solid-svg-icons'
import { Draggable, Navbar, NinaDataLoader } from 'components'
import { useHistory } from 'react-router-dom'
import { pathFor } from 'constants/apiPaths'
import { OrganisationSelectionModal, OrganisationSelectionDropdown } from './misc'


function Page() {
    const history = useHistory()
    const propToBeAttached = {}
    const orgs = JSON.parse(localStorage.getItem("organisations"))
    const currentView = sessionStorage.getItem("currentView")

    const [ error, setError ] = useState(null)
    const [ propLocation, setPropLocation ] = useState("upperProp")
    const [ organisations, setOrganisations ] = useState(orgs || [])
    const [ organisationNames, setOrganisationNames ] = useState([]) 
    const [ currentOrg, setCurrentOrg ] = useState(JSON.parse(sessionStorage.getItem("currentOrg")))
    const [ username, setUsername ] = useState("") 
    const [ organisationModalOpen, setOrganisationModalOpen ] = useState(false)
    const [ collapsed, setCollapsed ] = useState(false)
    const [ currentOrgChanged, setCurrentOrgChanged ] = useState(false)
    const [ ninaLoader, setNinaLoader ] = useState(false)
    const [ dashboardType, setDashboardType ] = useState(currentView)
    const [ pages, setPages ] = useState(null)
    const [ title, setTitle ] = useState(pages?.[0]?.text || "")
    
    const handleCollapse = () => setCollapsed(!collapsed) 
    
    const organisationsDropdownProps = { currentOrg, setCurrentOrg, organisations, organisationNames }
    const propChild = (
        <Draggable className={ propLocation === "lowerProp" || "m-3" } setPropLocation={ setPropLocation }>
            {
                organisationNames?.length === 1 ? (
                    <div className='p-2 mx-1 border bg--gray rounded'>
                        <span className='dropdown-mini-label rounded-pill'>Accounts</span>
                        { organisationNames[0]}
                    </div>
                ) : (
                    <OrganisationSelectionDropdown { ...organisationsDropdownProps } propLocation={ propLocation } />
                )
            }
        </Draggable>
    )
    propToBeAttached[propLocation] = propChild
    const sidebarProps = { pages, setTitle, collapsed, ...propToBeAttached }
    const navbarProps = { title, username, handleCollapse, dashboardType, setDashboardType, internal: currentOrg?.org_type === "internal" }
    const routesProps = { pages, setOrganisations, currentOrg, currentOrgChanged, ninaLoader, setNinaLoader, dashboardType }

    useEffect(async () => {
        try {
            await Request("/verify-token")
        } catch (err) {
            return setError("Connection failure!!!")
        }

        const organisationsArray = localStorage.getItem("organisations")
        if (organisationsArray) {
            const orgs = JSON.parse(organisationsArray)
            orgs?.length && setOrganisations(orgs)
        }
    }, [])

    useEffect(() => {
        if (organisations?.length) {
            setOrganisationNames(organisations.map(o => o.organisation_name))
            if (organisations?.length == 1) {
                setCurrentOrg(organisations[0])
            } else if (!sessionStorage.getItem("currentOrg")) {
                setTitle("")
                setOrganisationModalOpen(true)
            }
        } else history.push("/accounts/login")
    }, [ organisations ])

    useEffect(async () => {
        if (currentOrg) {
            setCurrentOrgChanged(false)
            const { organisation_id } = currentOrg
            const { statusCode, jsonResponse } = await Request(pathFor.AccountDetails, { organisation_id })
            if (statusCode === 200) {
                setUsername(jsonResponse.name)
                sessionStorage.setItem("accountDetails", JSON.stringify(jsonResponse))
            }
            sessionStorage.setItem("currentOrg", JSON.stringify(currentOrg))
            setCurrentOrgChanged(true)
            setDashboardType(currentOrg.org_type === "internal" ? currentView || "advertiser" : currentOrg.org_type)
        }
    }, [ currentOrg ])

    useEffect(() => {
        if (dashboardType) {
            let pageFilters = [];

            pageFilters.push(`${dashboardType}Exclude`)
            currentOrg.owned || pageFilters.push("hiddenFromMembers")            
            if (currentOrg?.org_type !== "internal") pageFilters.push("internalOnly")
            
            setPages(dashboardPages(pageFilters))
        }
    }, [ dashboardType, currentOrgChanged ])


    return (
        <div className="App bg--body">
            <Navbar { ...navbarProps } />
            {
                organisationModalOpen ? (
                    <OrganisationSelectionModal callbackFn={() => { setTitle(pages[0]?.text); setOrganisationModalOpen(false); }} _props={ organisationsDropdownProps } organisationModalOpen={ organisationModalOpen } />
                ) : currentOrg && pages ? (
                    <div className="d-flex page-container">
                        <Sidebar { ...sidebarProps } />
                        {
                            error && currentOrg ? (
                                <div className="network-failure">
                                    <div className='position-relative'>
                                        <FontAwesomeIcon icon={ faPlug } className="network pl-3" size='10x'/>
                                        <FontAwesomeIcon icon={ faSlash } className="failure pr-2 pt-2" size='8x'/>
                                    </div>
                                    <h1 className='font-weight-bold ml-4 pt-2'>{ error }</h1>
                                </div>
                            ) : (
                                <div className="flex-grow-1">
                                    <Routes { ...routesProps } />
                                </div>
                            )
                        }
                    </div>
                ) : <></>
            }
            { ninaLoader && <NinaDataLoader text={ ninaLoader } /> }
		</div>
    )
}

export default Page
