import React from 'react'
import TruncatedText from './TruncatedText'

function Anchor(props) {
    const { url } = props

    return (
        <a href={ url } target="_blank" className="url-text">
            {
                url.length > 60 ? (
                    <TruncatedText value={ url } length={ 60 } />
                ) : url
            }
        </a>
    )
}

export default Anchor