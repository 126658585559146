const previewWebDataset = [
    {
        key: "url",
        title: "URL",
		sortable: true
    },
	{
		key: "geo",
		title: "Geo",
		sortable: true
	},
	{
		key: "impressions",
		title: "Impressions",
		isInt: true,
		inThousands: true,
		sortable: true
	},
	{
		key: "avg_cpm",
		title: "Average CPM ($)",
		sortable: true
	},
	{
		key: "ad_slots",
		title: "Ad slots",
		isInt: true,
		sortable: true,
		default: "N/A"
	},
	{
		key: "taxonomy",
		title: "Taxonomy",
		sortable: true
	},
	{
		key: "score",
		title: "Relevancy",
		sortable: true
	}
];

const previewInAppDataset = [
	{
		key: "url",
		title: "URL",
	},
	{
		key: "store_name",
		title: "Store",
	},
]

const accountDetailsTable = [
	{
		key: "name",
		title: "Name",
	},
	{
		key: "email",
		title: "Email",
	},
	{
		key: "company_name",
		title: "Company",
	},
];

const taxonomiesTable = [
	{
		key: "taxonomy",
		title: "Taxonomy category"
	}, 
	{
		key: "rank",
		title: "Rank",
		isInt: true,
	}
]

const usersTableHead = [
	{
		title: "Name",
		key: "member_name",
		sortable: true
	},
	{
		title: "Email",
		key: "member_email",
		sortable: true
	},
	{
		title: "Status",
		key: "status",
		capitalise: true,
		sortable: true
	},
	{
		actions: [
			{
				text: "Edit",
				key: "edit",
				path: "/edit-user"
			}, 
			{
				text: "Resend activation",
				key: "resend",
				dependentOn: "status",
				noDisplayIf: ["active", "inactive"],
				path: `/resend-activation/${JSON.parse(sessionStorage.getItem("currentOrg"))?.organisation_id}`
			}
		]
	}
]

const usersActivitiesHead = [
	{
		title: "Task",
		key: "task",
		sortable: true
	},
	{
		title: "Status",
		key: "status",
		capitalise: true,
		sortable: true
	},
	{
		title: "Created by",
		key: "created_by",
		sortable: true,
		hiddenFromMembers: true
	}
]

export { previewWebDataset, previewInAppDataset, accountDetailsTable, taxonomiesTable, usersTableHead, usersActivitiesHead };
