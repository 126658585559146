import { TextArea } from 'components'
import React, { useState } from 'react'
import DatasetFilters from '../common/DatasetFilters'
import PageLayout from '../common/PageLayout'
import SuggestURLS from '../common/SuggestURLS'


function KeywordsTargeting(props) {
	const { setNinaLoader } = props
	const [ geoLocations, setGeoLocations ] = useState([])
    const [ languages, setLanguages ] = useState([])
    const [ sentiments, setSentiments ] = useState([])
    const [ intents, setIntents ] = useState([])
	const [ keywords, setKeywords ] = useState("")
	const [ errorText, setErrorText ] = useState("")
    const [ tableBody, setTableBody ] = useState([])

	const [ geos, setGeos ] = useState(null)
	const [ payload, setPayload ] = useState(null)

	const featuresSet1 = {
		geolocation: {
			selected: geoLocations,
			setSelected: setGeoLocations,
			filter: true,
			multi: true,
			selectedFirst: true
		},
		language: {
			selected: languages,
			setSelected: setLanguages
		}
	}

	const featuresSet2 = {
		intent: {
			selected: intents,
			setSelected: setIntents,
			multi: true
		},
		sentiment: {
			selected: sentiments,
			setSelected: setSentiments,
			multi: true
		}
	}

	const textAreaProps = {
		className: "mb-responsive", label: "Keywords", placeholder: "Enter keywords (comma separated)",
		onChange: (e => setKeywords(e.target.value.trim())), rows: "4", errorText, setErrorText
	}
	const pageLayoutProps = { 
		features: { geoLocations, languages, intents, emotions: sentiments, keywords },
		primaryQuery: { keywords },
		errorCallbackFns: { keywords: setErrorText },
		tableBody, setTableBody, setNinaLoader,
        geos, setGeos, payload, setPayload
	}
  	return (
        <PageLayout { ...pageLayoutProps }>
			<DatasetFilters features={ featuresSet1 } />
			<TextArea { ...textAreaProps } />
			<DatasetFilters features={ featuresSet2 } >
				<SuggestURLS { ...pageLayoutProps } />
			</DatasetFilters>
        </PageLayout>
    )
}

export default KeywordsTargeting