import Request from "services/Request"


const fetchBidstreamData = async (geoLocations, n) => {
    const { statusCode, jsonResponse } = await Request("/fetch-bidstream-data", { geoLocations, n })
    return statusCode === 200 ? jsonResponse : { error: "Failed to fetch bidstream data" }
}


function BidstreamWrapper(bidstream_records, bidstream_domains, geoLocations, results, setTableBody, setTBody, setMessage) {

    if (bidstream_records?.length && bidstream_domains?.length) {
        setTableBody(results.map(item => {
            let data = { ...item }

            const taxonomy = data.taxonomy
            if (typeof taxonomy !== "string") {
                data.taxonomy = taxonomy.label
                data.score = `${(taxonomy.score * 100).toFixed(2)}%`
            }

            const domainIndex = bidstream_domains.indexOf(data.domain)
            const bidstreamRecord = bidstream_records[domainIndex]

            data.ad_slots = parseInt(bidstreamRecord["ad_slots"])
            data.avg_cpm = bidstreamRecord.avg_cpm
            data.impressions = bidstreamRecord.impressions
            data.geo = bidstreamRecord.geo.filter(g => geoLocations.includes(g)).join(", ")

            return data
        }))
    } else {
        setMessage({info: "No data found."})
        setTableBody([])
        setTBody(results)
    }
}


export { fetchBidstreamData }
export default BidstreamWrapper