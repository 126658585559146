import React from "react";
import Banner from "components/Banner";
import { banners } from "constants/bannerConstants";
import UsersActivities from "./UsersActivities";
import DomainsReport from "./DomainsReport";


function Overview(props) {
	const { currentOrg, dashboardType } = props
	const { organisation_id, owned, organisation_type } = currentOrg

	const Component = dashboardType === organisation_type === "publisher" ? DomainsReport : UsersActivities

	return (
		<div className="h-100 d-flex flex-column">
			<div className="row mx-3 mt-4">
				{
					banners(dashboardType).map((banner, idx) => banner.bannerKey === "UsersStats" && !owned ? (<></>) : (
						<Banner key={ `${idx}` } { ...banner } organisation_id={ organisation_id }/>
					))
				}
			</div>
			<Component { ...currentOrg } />
		</div>
	);
}

export default Overview;
