import { Btn, Modal } from 'components'
import { pathFor } from 'constants/apiPaths'
import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from "react-csv"
import useSnackbar from 'services/useSnackbar'
import { fetchBidstreamData } from '../utils/BidstreamWrapper'
import { handleCustomDatasetPayload } from '../utils/PayloadHandlers'
import RequestHandler from '../utils/RequestHandler'


function DownloadModal(props) {
    const setMessage = useSnackbar()
    const { payload, geoLocations, customer_name, setNinaLoader, setModalMode, extras, tableUrls, organisation_id } = props
    const [ attrs, setAttrs ] = useState({})
    const [ urls, setUrls ] = useState(tableUrls || [])

    const [ isModalOpen, setIsModalOpen ] = useState(null)
    const [ filename, setFilename ] = useState("")
    const [ expectedURLsError, setExpectedURLsError ] = useState(null)
    const [ target_size, setTargetSize ] = useState(null)
    const csvRef = useRef()

    const closeModal = () => {
        setModalMode(null)
        setIsModalOpen(false)
    }
    
    useEffect(async () => {
        const urlsLength = urls?.length
        if (urlsLength) {
            let displayValue, maxValue;
            displayValue = maxValue = urlsLength;
            if (urlsLength >= 2000) {
                displayValue = "2000+"
                maxValue = 200000
            }
            setTargetSize(maxValue)
            setAttrs({ displayValue, maxValue })
            setIsModalOpen(true)
        } else if (!tableUrls) {
            if (geoLocations?.length) {
                const bidstreamData = await fetchBidstreamData(geoLocations, 28)
                if ("error" in bidstreamData) return setMessage(bidstreamData)
                                
                const { bidstream_domains } = bidstreamData
                payload["domains"] = bidstream_domains
            } 

            const apiPath = `${pathFor.RecommendationEngine}?urls_only=1`
            RequestHandler(apiPath, payload, setMessage, setNinaLoader, setModalMode, setUrls)
        }
    }, [ urls ])
    
    const handleTargetSize = (value) => {
        const re = /^[0-9]+$/
        if (!re.test(value)) {
            return setExpectedURLsError("Not a valid number.")
        }

        value = parseInt(value)    
        if (value <= 0) {
            return setExpectedURLsError('Must not be less than 1.')
        }
        
        if (value && value > attrs.maxValue) {
            return setExpectedURLsError(`Must not exceed ${attrs.maxValue}.`)
        }

        setTargetSize(value)
    }

    const handleExpectedURLs = event => {
        setExpectedURLsError(null)
        const value = event.target.value
        if (!value?.length) return
        handleTargetSize(value)   
    }
    
    const handleDownload = () => {
        let action = "export"
        if (target_size < 2000) {
            action = "download"
            csvRef.current.link.click()
        }
        
        let misc = { target_size, filename_prefix: filename, organisation_id }
        if (customer_name?.length) {
            misc = { ...misc, ...(extras || {}), customer_name }
        }
        
        const apiPath = `${pathFor.RecommendationEngine}?action=${action}`
        RequestHandler(apiPath, { ...payload, ...misc }, setMessage, setNinaLoader, setModalMode)

        closeModal()
    }

    
    return (
        <Modal handleClose={ closeModal } isOpen={ isModalOpen } className="w-75" >
            <h5 className='text--primary font-weight-bold'>Download options</h5>
            <div className='p-4 bg--secondary rounded mt-3'>
                <div className='row align-items-center mb-3'>
                    <p className='text-muted font-weight-bold col-2 mb-0'>Total results</p>
                    <span className='col-1 px-1'>:</span>
                    <div className='col-9'>
                        <p className='text-secondary pl-3 mb-0'>{ attrs.displayValue }</p>
                    </div>
                </div>
                <div className='row align-items-center mb-3'>
                    <p className='text-muted font-weight-bold col-2 mb-0'>Expected URLs</p>
                    <span className='col-1 px-1'>:</span>
                    <div className='col-9'>
                        { expectedURLsError ? <i className='text-danger expected-urls-error'>{ expectedURLsError }</i> : "" }
                        <input 
                            className='w-100 px-3 border rounded py-1 mr-3' 
                            onChange={() => setExpectedURLsError(null)}
                            placeholder={ attrs.maxValue }
                            onBlur={ handleExpectedURLs }
                            onFocus={() => target_size || setTargetSize(attrs.maxValue)}
                        />
                    </div>
                </div>
                <div className='row align-items-center mb-3'>
                    <p className='text-muted font-weight-bold d-flex col-2 mb-0'>
                        File name { customer_name ? "" : <span className='text-danger mr-2'>*</span> }
                    </p>
                    <span className='col-1 px-1'>:</span>
                    <div className='col-9'>
                        <input className='w-100 px-3 border rounded py-1 mr-3' onChange={e => setFilename(e.target.value.trim())} />
                    </div>
                </div>
                
                <div className='d-flex justify-content-between mt-3 pt-1 align-items-center'>
                    <i>{ attrs.maxValue < 2000 ? "" : "Note: Dataset of more than 2000 URLs will be sent via mail, to your registered email address."}</i>
                    <Btn
                        className="cw-2"
                        disabled={ expectedURLsError || (!customer_name && !filename?.length) }
                        onClick={ handleDownload }
                    >
                        Download
                    </Btn>
                </div>
            </div>
            {
                urls && <CSVLink ref={ csvRef } data={[["URL"], ...urls.slice(0, target_size).map(item => [item])]} filename={ `${filename}${customer_name || ""}_1to${target_size}.csv` } target="_blank"></CSVLink>
            }
        </Modal>
    )
}

export default DownloadModal
