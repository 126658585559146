import { TextArea } from 'components'
import React, { useEffect, useState } from 'react'
import Request from 'services/Request'
import useSnackbar from 'services/useSnackbar'
import DatasetFilters from '../common/DatasetFilters'
import PageLayout from '../common/PageLayout'
import SuggestURLS from '../common/SuggestURLS'
import TaxonomyItem from '../misc/TaxonomyItem'


function CustomSearch(props) {
	const { setNinaLoader } = props
	const setMessage = useSnackbar()	
    const [ geoLocations, setGeoLocations ] = useState([])
    const [ languages, setLanguages ] = useState([])
    const [ sentiments, setSentiments ] = useState([])
    const [ intents, setIntents ] = useState([])
	const [ taxonomyList, setTaxonomyList ] = useState([])
    const [ taxonomies, setTaxonomies ] = useState([])
    
    const [ allOfTheseKeywords, setAllOfTheseKeywords ] = useState("")
    const [ allOtkErrorText, setAllOtkErrorText ] = useState("")
    const [ anyOfTheseKeywords, setAnyOfTheseKeywords ] = useState("")
    const [ anyOtkErrorText, setAnyOtkErrorText ] = useState("")
    const [ noneOfTheseKeywords, setNoneOfTheseKeywords ] = useState("")
    const [ noneOtkErrorText, setNoneOtkErrorText ] = useState("")

    const [ tableBody, setTableBody ] = useState([])

    const [ geos, setGeos ] = useState(null)
    const [ payload, setPayload ] = useState(null)

	useEffect(async () => {
		try{
            const { statusCode, jsonResponse } = await Request("/fetch-taxonomies")
            if (statusCode === 200) {
                const { data } = jsonResponse
                setTaxonomyList(data)
            } else setMessage(jsonResponse)
        } catch {
            setMessage({error: "Server error. Try again after sometime."})
        }
	}, [])

	const featuresSet = {
		geolocation: {
			selected: geoLocations,
			setSelected: setGeoLocations,
			filter: true,
			multi: true,
			selectedFirst: true
		},
		language: {
			selected: languages,
			setSelected: setLanguages
		},
		intent: {
			selected: intents,
			setSelected: setIntents,
			multi: true
		},
		sentiment: {
			selected: sentiments,
			setSelected: setSentiments,
			multi: true
		}
	}
	const pageLayoutProps = { 
        features: { geoLocations, languages, intents, emotions: sentiments, taxonomies },
        misc: { keywords_all_inclusive: allOfTheseKeywords, keywords_whitelist: anyOfTheseKeywords, keywords_blacklist: noneOfTheseKeywords },
        primaryQuery: { custom_search: taxonomies }, 
        errorCallbackFns: {  keywords_all_inclusive: setAllOtkErrorText, keywords_whitelist: setAnyOtkErrorText, keywords_blacklist: setNoneOtkErrorText },
        tableBody, setTableBody, setNinaLoader,
        geos, setGeos, payload, setPayload
    }

    return (
		<PageLayout { ...pageLayoutProps }>
			<div className='row'>
				<div className='col'>
                    <TextArea rows="3"
                        className="mb-responsive"
                        placeholder="Any of these keywords"
                        onChange={e => setAnyOfTheseKeywords(e.target.value.trim())}
                        errorText={ anyOtkErrorText } setErrorText={ setAnyOtkErrorText }
                    />
					<span className='label-text'>
						Taxonomy categories
					</span>
					<div className="scrollable taxonomies-container mt-2 border rounded-lg">
                        {
                            taxonomyList?.length ? taxonomyList.map((option, idx) => (
                                <TaxonomyItem dropDownItem={ option } taxonomies={ taxonomies } setTaxonomies={ setTaxonomies } idx={ idx } />
                            )) : <></>
                        }
					</div>
				</div>
				<div className='w-50 fixed-col'>
                    <div className='px-3'>
                        <TextArea rows="3"
                            className="mb-responsive"
                            placeholder="All of these keywords"
                            onChange={e => setAllOfTheseKeywords(e.target.value.trim())}
                            errorText={ allOtkErrorText } setErrorText={ setAllOtkErrorText }
                        />
                        <TextArea rows="3"
                            className="mb-responsive"
                            placeholder="None of these keywords"
                            onChange={e => setNoneOfTheseKeywords(e.target.value.trim())}
                            errorText={ noneOtkErrorText } setErrorText={ setNoneOtkErrorText }
                        />
                    </div>
					<DatasetFilters features={ featuresSet } className="d-flex flex-column" />
                    <div className='text-right mr-3'>
                        <SuggestURLS { ...pageLayoutProps } />
                    </div>
				</div>
			</div>
		</PageLayout>
    )
}

export default CustomSearch
